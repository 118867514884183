.new-robosell-warning {
    background-color: #2478b4;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    text-align: left;
    margin-bottom: -11px;
    margin-left: -20px;
    margin-right: -30px;
    width: calc(100% + 40px);
}
.new-robosell-warning span {
    display: inline-block;
    font-size: 13px;
    margin: 0 12px;
}
.new-robosell-warning svg {
    width: 40px;
}
.ligreen-wrap {
    text-align: left;
}
.ligreen-wrap .modal-title {
    font-weight: bold;
    margin-bottom: 14px;
}
.ligreen-wrap img {
    display: block;
    margin: 16px auto 20px;
    width: 260px;
}
.ligreen {
    display: flex;
    padding: 10px 0;
}
.ligreen-ttl {
    font-weight: bold;
}
.ligreen span:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00ab6b;
    color: #fff;
    width: 20px;
    margin-right: 10px;
    height: 20px;
    border-radius: 30px;
    margin-top: 5px;
}
.ligreen span:nth-child(2) {
    width: calc(100% - 40px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}
.ligreen span {
    font-size: 13px;
}
.ligreen span b {
    margin-bottom: 4px;
}