.font-bold {
	font-weight: bold;
}
.margin-top-18 {
	margin-top: 18px;
}
.errorspan {
	color: #FE0000
}
.nopadding {padding: 0 !important;}
.nopaddingtop {padding-top: 0 !important;}
.nopadding-horizontal {padding-left: 0 !important;padding-right: 0 !important;}
.nomargin {margin: 0 !important;}
.noborder {border: 0 !important;}
.margin-top-5{margin-top:5px;}
.margin-2{margin:2px;}
.margin-10{margin:10px;}
.margin-bottom-5{margin-bottom:5px;}
.padding-top-5{padding-top:5px !important;}
.margin-top-10{margin-top:10px;}
.margin-top-15{margin-top:15px;}
.margin-bottom-10{margin-bottom:10px;}
.margin-left-10{margin-left:10px;}
.margin-bottom-20{margin-bottom:20px;}
.margin-left-20{margin-left:20px;}
.margin-top-20{margin-top:20px !important;}
.margin-30{margin:30px;}
.margin-bottom-30{margin-bottom:30px;}
.margin-left-30{margin-left:30px;}
.margin-right-10{margin-right:10px;}
.margin-top-0{margin-top:0px;}

.padding-30{padding:30px;}
.padding-top-30{padding-top:30px;}
.padding-bottom-30{padding-bottom:30px;}
.padding-left-30{padding-left:30px;}
.padding-right-30{padding-right:30px;}
.padding-top-bottom-5{padding: 5px 0px;}
.padding-top-50{padding-top:50px;}
.padding-top-70{padding-top:70px;}

.margin-30{margin:30px;}
.margin-top-30{margin-top:30px;}
.margin-left-30{margin-left:30px;}
.margin-right-30{margin-right:30px;}

.padding-50{padding:50px;}
.padding-top-50{padding-top:50px;}
.padding-bottom-50{padding-bottom:50px;}
.padding-left-50{padding-left:50px;}
.padding-right-50{padding-right:50px;}

.margin-50{margin:50px;}
.margin-top-50{margin-top:50px !important;}
.margin-bottom-50{margin-bottom:50px;}
.margin-left-50{margin-left:50px;}
.margin-right-50{margin-right:50px;}