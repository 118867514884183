.bit-link-prospektus-list {
    display: block;
    font-size: 13px;
    color: #E82529;
    border-bottom: 1px solid #ededed;
    padding: 4px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    width:100%
}
.bit-link-prospektus-list p {
  max-width: 90%;
  margin-right: 20px;
  overflow:hidden;
  text-overflow: ellipsis;
}
.bit-link-prospektus-list svg {
  min-width:25px
}