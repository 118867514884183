.bit-link-new-pin {
    text-align: center;
}
.bit-pin-desc {
    padding-top: 20px; 
    color: #bbb; 
    font-weight: 400;
    font-size: 13px; 
    text-align: center; 
    margin-left: 15px; 
    margin-right: 15px;
    margin-top: 4px;
}
.bit-new-pin {
    display: flex;
    align-items: center;
    height: 28px;
    justify-content: center;
    height: 28px;
    margin-top: 14vw;
}
.bit-new-pin div {
    border: solid 1px #b5b5b5;
    border-radius: 100%;
    box-shadow: none;
    background: transparent;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
    padding: 0;
    height: 10px;
    width: 10px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: transparent;
    caret-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.bit-new-pin div.done {
    background: #00ab6b;
    border:solid 1px #00ab6b;
    color: #00ab6b;
    box-shadow: 0px 0px 0px 5px rgba(210,243,230,1);
    -ms-box-shadow: 0px 0px 0px 5px rgba(210,243,230,1);
    -moz-box-shadow: 0px 0px 0px 5px rgba(210,243,230,1);
    -webkit-box-shadow: 0px 0px 0px 5px rgba(210,243,230,1);
    height: 12px;
    margin-left: 9px;
    margin-right: 9px;
    width: 12px;   
    animation: ipt-anim-pulse 0.5s ease;
    -webkit-animation: ipt-anim-pulse 0.5s ease;
}
.bit-custom-keyboard {
    background: #fff;
    display: flex;
    justify-content: center;
    flex-basis: auto;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    margin-left: -20px;
    bottom: 42px;
}
.bit-custom-keyboard div {
    color: #000000;
    font-size: 28px;
    text-align: center;
    padding: 12px 0;
    width: 33.33%;
}
.bit-custom-keyboard div:nth-child(1) {
    border: 0;
    border-bottom: 1px solid;
    border-image: linear-gradient(to left, #e5e5e5 0%, #fff 100%);
    border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(2) {
    border: 0;
    border-bottom: 1px solid #e5e5e5;
    border-left: 1px solid;
    border-right: 1px solid;
    border-image: linear-gradient(to bottom, #fff 0%, #e5e5e5 100%);
    border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(3) {
    border: 0;
    border-bottom: 1px solid;
    border-image: linear-gradient(to right, #e5e5e5 0%, #fff 100%);
    border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(4) {
    border: 0;
    border-bottom: 1px solid;
    border-image: linear-gradient(to left, #e5e5e5 0%, #fff 100%);
    border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(5) {
    border: 0;
    border: 1px solid #e5e5e5;
    border-top: 0;
}
.bit-custom-keyboard div:nth-child(6) {
    border: 0;
    border-bottom: 1px solid;
    border-image: linear-gradient(to right, #e5e5e5 0%, #fff 100%);
    border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(7) {
    border: 0;
    border-bottom: 1px solid;
    border-image: linear-gradient(to left, #e5e5e5 0%, #fff 100%);
    border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(8) {
    border: 0;
    border: 1px solid #e5e5e5;
    border-top: 0;
}
.bit-custom-keyboard div:nth-child(9) {
    border: 0;
    border-bottom: 1px solid;
    border-image: linear-gradient(to right, #e5e5e5 0%, #fff 100%);
    border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(10) {}
.bit-custom-keyboard div:nth-child(11) {
    border: 0;
    border-left: 1px solid;
    border-right: 1px solid;
    border-image: linear-gradient(to bottom, #e5e5e5 0%, #fff 100%);
    border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(12) {}
.bit-link-new-pin .login-register-icon img {
    height: 90px;
}
@-webkit-keyframes ipt-anim {
    0% {
        background: #fff;
        border:solid 1px #fff;
        box-shadow: none;
        color: #666;
    }
    50% {
        background: #fff;
        border:solid 1px #fff;
        box-shadow: none;
        color: #666;
    }
    60% {
        background: #00ab6b;
        border:solid 1px #ededed;
        box-shadow: 0px 0px 0px 5px rgba(210,243,230,1);
        color: #00ab6b;
    }
    100%{ 
        background: #00ab6b;
        border:solid 1px #00ab6b;
        box-shadow: 0px 0px 0px 5px rgba(210,243,230,1);
        color: #00ab6b;
    }
}
@-webkit-keyframes ipt-anim-pulse {
    0% {
        box-shadow: 0px 0px 0px 0px rgba(255,255,255,0.5);
    }
    100% {
        box-shadow: 0px 0px 0px 5px rgba(210,243,230,1)
    }
}

@media (min-height: 640px) {
    .bit-new-pin {
        margin-top: 16vh;
    }
    .bit-link-new-pin .login-register-icon img {
        height: 14vh;
    }
}

@media only screen and (min-width: 1030px) {
    .bit-link-new-pin .login-register-icon img {
        height: 90px !important;
    }
}
