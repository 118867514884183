.info-modal-body {
    /* padding: 20px; */
    padding-top: 0;
    text-align: left;
}

.info-modal-body h5, .info-modal-body p {
    font-size: 14px;
}
.info-modal-body h5 {
    margin: 18px 0 10px;
}

.info-modal-wrap .am-modal-content {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.info-modal-wrap p {
    line-height: 1.55;
    font-size: 13px;
    color: #333;
    margin: 10px 0 0;
    text-align: left;
}

.info-modal-wrap li {
    margin-bottom: 15px;
    text-align: left;
}

.info-modal-wrap .am-modal-header {
	padding: 0px 20px 0px 20px;
}

.infomodal-close-wrap {
    width: 20%;
    float: right;
    position: absolute;
    right: 0;
    margin: 5px 0px;
    display: none;
}

.info-modal-close {
    float: right;
    margin: 18px 15px 15px 15px;
    width: 15px;
    height: 15px;
    color:  #B5B5B5;
}

.info-modal-wrap h4 {
	font-size:  16px;
	font-weight: 600;
	text-align: left;
	margin-top: 15px;
}

.info-modal-wrap .am-modal-close-x {
    background-size: 10px;
	width:  15px;
	height: 15px;
	margin: 9px 0 4px 15px;
}

.info-modal-wrap .am-modal-close {
	right:  30px;
}

.info-modal-wrap ul {
	list-style: none;
	padding: 0;
	margin: 0;
}


