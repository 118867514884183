.mailPage {
	padding: 0 4px;
	background: #fff;
	text-align: center;
}

@media screen and (max-width: 320px) {
	.mailPage {
		padding: 15px;
	}
}

.mailPageImg {	height: 80px; margin: 20px auto}
.mailPageDesc {	margin-bottom: 10px; font-size: 14px}
.mailPageDesc1 {	font-size: 14px}
.mailPageDesc2 {
	color: #00ab6b; 
	font-weight: bold; 
	padding-top: 5px;     
	font-family: monospace;
    font-size: 18px;
}
.mailPageDesc + div {
    font-size: 14px;
}

@media screen and (max-width: 320px) {
	.mailPageDesc2 {
		padding: 10px 0px;
	}
}

.mailPageBtnWrap {	display: flex; justify-content: space-between; margin-top: 10px}
.mailPageBtnChild {	width: calc(50% - 10px); padding: 10px 0; }
.mailPageBtn{
	height: 40px;
	display: flex;
	border: solid 1px #333;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	color: #333;
	font-size: 14px;
}

@media screen and (max-width: 320px) {
	.mailPageBtn {
		font-size: 12px;
	}
}

.ooo8 {	width: calc(50%); padding: 10px 0; padding-left: 10px}
.loader {
	border: 2px solid transparent;
	border-radius: 50%;
	border-top: 2px solid rgba(0,0,0,0.5);
	border-right: 2px solid rgba(0,0,0,0.5);
	border-bottom: 2px solid rgba(0,0,0,0.5);
	width: 16px;
	height: 16px;
	margin-left: 5px;
	margin-right: 5px;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s  linear infinite;
  }
  
  @-webkit-keyframes spin {
	0% { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }
  