
.not-found {
  padding: 40px 0;
  height: 422px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.not-found img {
  width: 200px;
  object-fit: contain;
  display: block;
  margin: 0 auto 20px;
}

.not-found .title {
  font-weight: 700;
  margin-bottom: 12px;
}

.not-found .description {
  color: #888;
}

.searchbar :global .am-search-cancel-anim{
  display: none;
}