/*----- Reset ------*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	/* font: inherit; */
	vertical-align: baseline;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
fieldset, img {
	border: 0;
}
address, caption, cite, code, dfn, em, strong, th, var {
	font-style: normal;
	font-weight: normal;
}
ul {
	list-style: none;
}
caption, th {
	text-align: left;
}
h1, h2, h3, h4, h5, h6 {
	font-size: 100%;
	font-weight: normal;
}
abbr, acronym {
	border: 0;
}
* { 
	-moz-box-sizing: border-box; 
	-webkit-box-sizing: border-box; 
	 box-sizing: border-box; 
}
:focus {
	outline: 0;
}

@font-face {
	font-family: 'muli-light';
	src: url('./../src/assets/fonts/muli/Muli-Light.ttf') format('truetype');
	font-weight: normal;
	font-style: normal
}
@font-face {
	font-family: 'muli-regular';
	src: url('./../src/assets/fonts/muli/Muli-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal
}
@font-face {
	font-family: 'beausans-semibold';
	src: url('./../src/assets/fonts/beausans/beausanspro-semibold.woff2') format('woff2'),
		 url('./../src/assets/fonts/beausans/beausanspro-semibold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'beausans-light';
	src: url('./../src/assets/fonts/beausans/beausanspro-light.woff2') format('woff2'),
		 url('./../src/assets/fonts/beausans/beausanspro-light.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
.muli-light {
	font-family: 'muli-light';
}
.muli-regular {
	font-family: 'muli-regular';
}
.beausan-light {
	font-family: 'beausans-light';
}
.beausan-semibold {
	font-family: 'beausans-semibold';
}
body {
	background: #fff !important;
	font-family: 'muli-regular', arial, sans-serif !important;
	font-size: 12px;
}
html:not([data-scale]) .am-list-body::before {
    background-color: #fff !important;
}

.bit-link-btn-gray {
	background: #9CA4AC;
	border: none;
	border-radius: 50px;
	color: #fff;
	font-family: 'beausans-semibold' !important;
	font-size: 14px;
	padding: 12px 74px;
}
.bit-link-btn-red {
	background: #FF0000;
	border: none;
	border-radius: 50px;
	color: #fff;
	font-family: 'beausans-semibold' !important;
	font-size: 14px;
	padding: 12px 74px;
}
.bit-link-btn-red-full {
	background: #FF0000;
	border: none;
	border-radius: 50px;
	color: #fff;
	display: block;
	font-family: 'beausans-semibold' !important;
	font-size: 14px;
	padding: 12px 14px;
	text-align: center;
	width: 100%;
}
.bit-link-btn-gray-full {
	background: #9CA4AC;
	border: none;
	border-radius: 5px;
	color: #fff;
	font-family: 'beausans-semibold' !important;
	font-size: 14px;
	padding: 12px 74px;
	width: 100%
}
.bit-link-border-gray {
	border-bottom: 3px solid #f1f1f1;
	border-top: 3px solid #f1f1f1;
	padding: 12px 10px;
}
.bit-link-centered {
	margin: 18px 14px !important;
}
.bit-link-centered img {
	margin: 18px 0 12px;
}
label {
	font-size: 10px;
	margin-bottom: 2px;
}
.bit-align-center {
	text-align: center;
}
.bit-link-wrap {
	display: block;
	height: 100%;
	padding: 20px;
	position: relative;
}
.bit-link-wrap.fix-height {
    height: calc(100% - 30px) !important;
}
.bit-link-header {
	align-items: center;
	display: flex;
	font-size: 16px;
	font-weight: bold;
	justify-content: space-between;
	margin-bottom: 24px;
}
.bit-link-header-fix {
    background: #fff;
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    justify-content: space-between;
    padding: 0 0 10px;
    position: fixed;
    width: calc(100% - 40px);
}
.bit-link-main-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.bit-link-main-header {
    background: #fff;
    align-items: center;
    display: flex;
    font-size: 16px;
	font-weight: bold;
	height: 56px;
    justify-content: space-between;
    padding: 20px 20px 8px;
    position: fixed;
	width: 100%;
	z-index: 99;
	-webkit-transform:translate3d(0,0,0);
	transform: translate3d(0, 0, 0);
}
.bit-link-main-header .bit-link-left-arrow {
    transform: translateX(-6px);
}
.bit-link-main-header div:nth-child(2),
.bit-link-main-header div:last-child {
    display: flex;
    min-width: 20px;
    align-items: center;
}
.bit-link-main-header div:first-child {
	min-width: 20px;
}
.bit-link-main-content {
    flex: 1;
    padding: 14px 20px 0;
    margin-top: 56px;
}
.bit-link-register {
	display: flex;
	height: calc(100% - 50px);
	flex-direction: column;
}
/* .bit-link-register p {
    color: #666666;
    margin-bottom: 16px;
    padding: 0 12px;
} */
.bit-link-content {
    display: flex;
    height: calc(100% - 30px);
    margin-top: 30px;
	flex-direction: column;
	overflow: auto;
	position: relative;
}
.bit-link-title-reg {
	font-family: 'beausans-semibold' !important;
	text-align: left;
}
.bit-link-title-reg h5 {
	font-size: 11px;
	margin-bottom: 5px;
}
.bit-link-title-reg h1 {
	font-size: 20px;
}
.bit-link-title-reg p {
	font-family: 'muli-light' !important;
	margin-top: 4px;
	font-size: 13px;
    line-height: 18px;
}
.bit-link-content-centered {
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	height: calc(100% - 100px);
}
.bit-link-content-centered h3 {
	font-family: 'beausans-semibold' !important;
	font-size: 18px;
}
.bit-link-phone {
	border: 1px dashed #E4E4E4;
	border-radius: 5px;
	color: rgba(102,102,102,0.5);
	font-size: 16px;
	margin-top: -20px;
	padding: 14px 0;
	text-align: center;
	width: calc(100% - 24px);
}
.bit-link-loader {
	margin: 15px 0;
	text-align: center;
}
.bit-link-loader p {
	color: rgba(102,102,102,0.5);
	margin-top: 8px;
}
.bit-link-loader span {
	background: #FF0000;
	border-radius: 10px;
	display: inline-block;
	height: 10px;
	margin: 0 4px;
	opacity: 0.5;
	width: 10px;
}
.bit-link-loader span:nth-child(1) {
	animation: link-loader-1 1s infinite linear;
}
.bit-link-loader span:nth-child(2) {
	animation: link-loader-2 1s infinite linear;
}
.bit-link-loader span:nth-child(3) {
	animation: link-loader-3 1s infinite linear;
}
.bit-link-loader span:nth-child(4) {
	animation: link-loader-4 1s infinite linear;
}
@keyframes link-loader-1 {
	0% {
		opacity: 1;
	}
	25% {
		opacity: 0.5;
	}
	50% { 
		opacity: 0.5;
	}
	75% { 
		opacity: 0.5;
	}
	100% { 
		opacity: 0.5;
	}
}
@keyframes link-loader-2 {
	0% {
		opacity: 0.8;
	}
	25% {
		opacity: 1;
	}
	50% { 
		opacity: 0.5;
	}
	75% { 
		opacity: 0.5;
	}
	100% { 
		opacity: 0.5;
	}
}
@keyframes link-loader-3 {
	0% {
		opacity: 0.5;
	}
	25% {
		opacity: 0.8;
	}
	50% { 
		opacity: 1;
	}
	75% { 
		opacity: 0.5;
	}
	100% { 
		opacity: 0.5;
	}
}
@keyframes link-loader-4 {
	0% {
		opacity: 0.5;
	}
	25% {
		opacity: 0.5;
	}
	50% { 
		opacity: 0.8;
	}
	75% { 
		opacity: 1;
	}
	100% { 
		opacity: 0.5;
	}
}
.bit-link-centered-img {
	margin: 12px 0;
}
.bit-link-pin-left {
	display: inline-block;
	margin-bottom: 20px;
	margin-top: 10px;
	position: relative;
	text-align: left;
	width: 100%;
}
.bit-link-pin-left input {
	border: 0 !important;
	border-bottom: solid 2px #e4e4e4 !important;
	border-radius: 0 !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	background: transparent !important;
	margin: 0 !important;
	margin-right: 16px !important;
	padding: 0 !important;
	width: 24px !important;
	text-align: center;
	font-family: 'beausans-semibold' !important;
	font-size: 20px !important;
	font-weight: bold;
	height: 42px;
}
.bit-link-pin-center {
	display: inline-block;
	margin: 35px 0 40px;
	position: relative;
	text-align: center;
	width: 100%;
}
.bit-link-pin-center input {
	border: 0 !important;
	border: solid 1px #E4E4E4 !important;
	border-radius: 0 !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	background: transparent !important;
	margin: 0 !important;
	margin-right: 16px !important;
	padding: 0 !important;
	width: 33px !important;
	text-align: center;
	font-family: 'beausans-semibold' !important;
	font-size: 20px !important;
	font-weight: bold;
	height: 45px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.bit-pin-time {
	position: absolute;
	right: 0;
	top: 13px;
}
.bit-pin-time a {
    color: #000;
}
.bit-already-txt {
	color: rgba(102,102,102,0.5);
	margin-top: 12px !important;
}
.bit-link-footer {
	background: #fff;
	align-items: center;
	display: flex;
	font-size: 10px;
	justify-content: center;
	position: absolute;
	bottom: 0px;
	padding: 14px 0;
	margin-left: -20px;
	width: 100%;
}
.bit-link-footer-stay {
	background: #fff;
	align-items: center;
	display: flex;
	font-size: 10px;
	justify-content: center;
	position: relative;
	padding: 14px 0;
	width: 100%;
}
.bit-link-footer div, .bit-link-footer-stay div {
	margin: 0 3px;
}
.bit-link-footer img, .bit-link-footer-stay div {
	margin-top: 3px;
}
.bit-link-card {
	border: 1px solid #E4E4E4;
	border-radius: 5px;
	margin: 0 0 20px;
	overflow: hidden;
	position: relative;
}
.bit-link-main-portfolio {
	overflow: hidden;
	position: relative;
	padding: 15px;
}
.bit-link-card-button {
	align-items: center;
	border-top: 1px solid #e4e4e4;
	color: #000;
	display: flex;
	font-size: 13px;
    justify-content: space-between;
    padding: 10px 15px;
}
.bit-link-card-button svg {
    transform: translateX(8px);
}
.am-modal-content {
    border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	padding: 14px 20px !important;
}
.am-modal-body {
    font-size: 14px;
    color: #000 !important;
		overflow-x: hidden;
}
.bit-link-modal-header {
    display: flex;
    align-items: flex-start;
	position: relative;
	justify-content: space-between;
}
.bit-link-modal-header h3 {
	font-weight: bold;
}
.bit-link-modal-header div:last-child {
    margin-top: 3px;
}
.bit-link-tutor-button {
	border: 1px solid #E4E4E4;
	border-radius: 50px;
	margin: 14px 0;
	padding: 10px 14px;
	text-align: left;
}
.bit-link-skip {
	color: #00AB6B;
	font-size: 14px;
}
.bit-link-register {
    padding-bottom: 20px;
}
.bit-link-register .am-list {
    text-align: left;
}
.bit-link-register .am-list-header {
	padding-bottom: 0;
	padding-left: 0;
}
.bit-link-register .am-list-item {
    padding-left: 0px;
}
.bit-link-register .am-list-item.am-input-item {
    padding-left: 0;
}
.bit-link-register .am-list-item .am-input-control input {
	font-family: 'muli-regular' !important;
	font-size: 16px;
}
html:not([data-scale]) .am-list-body::before {
    height: 0px;
}
.bit-link-register .am-list-item .am-list-line .am-list-extra {
    display: none;
}
.bit-link-register .am-list-item .am-list-line .am-list-content {
    align-items: center;
	display: flex;
	font-size: 16px;
    justify-content: space-between;
}
.bit-link-register .am-list-item .am-list-line {
    padding-right: 0px;
}
.bit-link-register .am-list:first-child .am-list-header {
    padding-top: 0;
}
.am-picker-popup-item {
    color: #00AB6B !important;
	font-size: 14px !important;
}
.am-picker-popup {
    border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}
.bit-link-blue-txt {
	color: #2578B4;
	font-size: 12px;
	line-height: 18px;
	margin-top: 8px;
	text-align: left;
}
.bit-link-upload-txt {
    color: #BBBBBB;
    font-size: 14px;
	margin: 20px 0 10px;
	text-align: left;
}
.bit-link-upload-form + .bit-link-upload-form {
	padding-bottom: 10px; 
	padding-top: 10px
}
.bit-link-upload-form > div {
	display: flex; 
	align-items: center;  
}
.bit-link-upload-form {
	display: flex; 
	align-items: center; 
	justify-content: flex-start;
	padding: 10px 0; 
	margin-left: 15px; 
	padding-right: 15px; 
	padding-top: 10px; 
	text-align: left;
}
.bit-link-upload-form svg {
    margin-left: auto;
}
.bit-link-col-1 { 
	color: #454545;
	font-size: 14px;
	margin-right: 10px; 
}
.bit-link-col-1 span { 
	border-radius: 2px;
	width: 65px;
	display: block;
	height: 40px;
	background: #f5f5f5;
	overflow: hidden;
	position: relative;
	text-align: center;
}
.bit-link-col-1 img { 
	height: 40px; 
}
.bit-link-signature {
	border: 1px solid #E4E4E4;
	border-radius: 5px;
	height: 100px;
	margin: 0 0 20px;
} 
.bit-link-fixed-foot {
    background: #fff;
    bottom: 0;
    padding: 16px 20px;
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 9;
}
.bit-link-close-step {
    position: absolute;
    right: 5px;
    top: 6px;
}
.bit-chart-accord {
	border-bottom: 1px solid #E4E4E4;
    transition: 0.4s;
    padding: 4px 15px;
}
.bit-chart-accord:last-child {
	border-bottom: 0;
}
.bit-chart-accord .bit-chart-head {
	padding: 10px 0px;
	cursor: pointer;
}
.bit-chart-accord .bit-chart-content {
	padding: 0 20px;
	/* max-height: 0; */
	display: none;
	overflow: hidden;
	transition: 200ms ease-in-out;
}
.bit-chart-accord.active > .bit-chart-content {
	padding: 10px 0;
	display: block;
	/* max-height: 600px; */
}
.bit-chart-accord > .bit-chart-head .bit-link-down-arr {
	transform: translate(6px, -1px) rotate(-90deg);
	-webkit-transition: 0.2s ease-out;
    -moz-transition: 0.2s ease-out;
    -o-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
}
.bit-chart-accord.active > .bit-chart-head .bit-link-down-arr {
	transform: translate(6px, -2px) rotate(0deg);
}
/* .bit-chart-accord.active .bit-reksa-return {
	transform: translate(4px, 5px);
} */
.bit-chart-accord.active .bit-link-item-row {
	align-items: flex-start;
}
.bit-link-regist-term {
	color: #BBBBBB;
	font-size: 12px;
	line-height: 18px;
	margin: 8px 0;
}
.bit-link-regist-term a {
	color: #00AB6B;
}
.bit-link-modal-centered {
    padding: 10px 0;
}
.bit-link-modal-centered h3 {
	font-family: 'beausans-semibold' !important;
	font-size: 18px;
}
.home-portfolio-top {
	text-align: left;
}
.bit-link-main-portfolio label {
	color: #9ca4ac;
	font-size: 13px;
}
.bit-portfolio-value span:first-child {
	font-family: 'beausans-light' !important;
	font-size: 18px;
}
.bit-portfolio-value span:last-child {
	font-family: 'beausans-semibold' !important;
	font-size: 24px;
	margin-left: 5px;
}
.home-portfolio-bottom {
	display: flex;
	margin-top: 14px;
	text-align: left;
	justify-content: space-between;
}
.home-portfolio-bottom p {
	color: #00AB6B;
	font-weight: bold;
	margin-top: 5px;
}
.home-portfolio-bottom p.plus {
	color: #00AB6B;
}
.home-portfolio-bottom p.min {
	color: #ee4a49;
}
.time-weighted-return {
    text-align: right;
}
.bit-link-transaction {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.bit-link-transaction-action[aria-disabled="true"]  {
	filter: grayscale(100%);
	pointer-events: none;
}

.bit-link-btn-red-full.bit-link-transaction-action[aria-disabled="true"]  {
	background-color: #ac9c9c;
}

.bit-link-transaction-sell.bit-link-transaction-action[aria-disabled="true"]  {
	color: #ac9c9c;
}

.bit-link-transaction-buy.bit-link-transaction-action[aria-disabled="true"]  {
	border-top: 1px solid #e4e4e4;
	background-color: #ac9c9c;
}

.bit-link-transaction div {
	flex: 1;
	padding: 16px 0;
}
.bit-link-transaction div:first-child {
	border-right: 1px solid #e4e4e4;
}
.bit-link-transaction div p {
	color: #000;
	font-size: 11px;
    margin-top: 8px;
}
.bit-link-invest-top {
	border-bottom: 1px solid #e4e4e4;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    text-align: left;
}
.bit-link-invest-top h5 {
	font-family: 'beausans-semibold' !important;
	font-size: 15px;
	margin-bottom: 4px;
}
.bit-link-invest-top p {
	color: #9ca4ac;
	font-size: 12px;
	line-height: 16px;
}
.bit-link-invest-top div:first-child {
    flex: 3;
}
.bit-link-invest-top div:last-child {
	flex: 1;
	text-align: right;
}
.bit-link-invest-bottom {
	padding: 0;
}
.bit-reksa-item {
	align-items: flex-start;
    display: flex;
	flex: 1;
	text-align: left;
}

.bit-reksa-item > div:nth-child(2) {
	flex: 1 1;
}

.bit-link-buy-badge {
	flex: unset !important;
}

.bit-reksa-item h3 {
	font-family: 'beausans-semibold' !important;
    font-size: 13px;
    margin: 2px 0;
}
.bit-reksa-item p {
    color: #b5b5b5;
    font-size: 11px;
}
.bit-link-info div:last-child {
    flex: 1;
}
.bit-link-info {
	display: flex;
	flex: 1 1;
}
.bit-reksa-pic {
    background: #ccc;
    border-radius: 50%;
    height: 34px;
	margin-right: 12px;
	margin-top: 2px;
    overflow: hidden;
    position: relative;
	width: 34px;
}
.bit-reksa-pic img {
    width: 100%;
}
.bit-link-item-row {
	align-items: center;
	display: flex;
	justify-content: space-between;
}
.bit-reksa-return {
	align-items: center;
    display: flex;
    justify-content: flex-end;
	max-width: 70px;
	text-align: right;
	transform: translateX(4px);
}
.bit-reksa-return h5 {
	color: #00AB6B;
	font-family: 'beausans-semibold' !important;
	font-size: 14px;
}
.bit-reksa-return p {
	color: #9ca4ac;
	font-size: 10px;
}
.minus {
	color: #ee4a49 !important;
}
.plus {
	color: #00AB6B !important;
}
.neutral {
	color: #000 !important;
}
.bit-chart-accord .bit-chart-head .bit-reksa-return p {
	display: none;
}
.bit-chart-accord.active .bit-chart-head .bit-reksa-return p {
	display: block;
	margin-top: 2px;
	white-space: nowrap;
}
.bit-link-reksa {
	align-items: center;
	display: flex;
	justify-content: center;
	height: 200px;
}
.bit-link-register-desc {
	font-size: 13px;
	line-height: 20px;
	/* padding: 0 12px 10px; */
	padding: 10px 12px 14px;
}
.bit-link-register-desc + .am-button {
    font-size: 13px;
}
.bit-link-saldo {
	align-items: center;
    background: #F5F6F8;
	display: flex;
	font-size: 14px;
    font-weight: bold;
    margin: 12px 0;
    justify-content: space-between;
    padding: 8px 12px;
}
.bit-link-buy-modal .am-modal-content .am-modal-body {
	overflow-x: hidden;
}
.bit-link-buy-modal-confirmation .am-modal-content {
    padding: 14px 0 !important;
}
.bit-link-buy-modal-confirmation .am-modal-body {
    padding: 0 20px !important;
}
.bit-link-payment-wrap {
	margin-left: -20px;
	margin-top: 5px;
    width: calc(100% + 40px);
}
.bit-link-payment-row {
	align-items: center;
	display: flex;
	font-size: 14px;
	justify-content: space-between;
	padding: 10px 20px;
}

.bit-link-payment-row > div:nth-child(1)  {
	text-align: left;
	padding-right: 10px;
}

.bit-link-payment-row > div:nth-child(2)  {
	text-align: right;
	min-width: max-content;
}
.bit-link-total {
	background: #F5F6F8;
	font-weight: bold;
	margin: 2px 0 20px;
	padding: 10px 20px;
}
.border-bottom-gray {
	border-bottom: 1px solid #EDEDED;
}
.portfolio-main-bg {
	background: url(../src/assets/images/bg-porto.png) no-repeat;
	background-size: 100%;
	margin-bottom: 20px;
	margin-left: -20px;
	padding: 22px 20px;
    width: calc(100% + 40px);
}
.bit-link-porto-top {
	padding: 14px 18px;
}
.bit-link-reksa-info {
	font-size: 13px;
	margin-bottom: 6px;
    margin-top: 12px;
}
.bit-link-reksa-info-row {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 4px 0;
}
.bit-link-reksa-info-row div:first-child {
	color: #9CA4AC;
}
.font-green-bibit {
	color: #00AB6B;
}
.bit-link-twin-button button {
	border: 0;
	font-family: 'beausans-light' !important;
	padding: 12px 0;
    width: 50%;
}
.bit-link-twin-button button:first-child {
	background: #fff;
    border-top: 1px solid #e4e4e4;
}
.bit-link-twin-button button:last-child {
	background: #ff0000;
    border-top: 1px solid #ff0000;
	color: #fff;
}
.border-top-gray {
	border-top: 5px solid #F5F5F5;
}
.bit-link-history-wrap {
	border-top: 5px solid #F5F5F5;
	padding: 5px 0;
}
.bit-link-history-wrap .bit-link-history-row:last-child {
	border-bottom: 0;
}
.bit-link-history-row {
	border-bottom: 10px solid #F5F5F5;
	padding: 6px 20px 0;
	position: relative;
}
.bit-link-history-flex {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-top: 14px;
}
.bit-link-history-flex div:last-child {
	align-items: center;
	color: #9CA4AC;
	display: flex;
	font-size: 10px;
}
.bit-link-transaction-status {
	background: #E2EAF1;
	color: #2478B4;
	font-size: 12px;
    padding: 6px 20px;
    margin-left: -20px;
    margin-top: 10px;
    text-align: left;
    width: calc(100% + 40px);
}
.bit-link-transaction-status-detail {
    background: #E2EAF1;
    border-radius: 5px;
    color: #2478B4;
    font-size: 12px;
    padding: 6px 8px;
    margin-top: 10px;
    text-align: left;
    width: 100%;
}
.bit-link-buy-badge {
    background: #00AB6B;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    color: #fff;
    font-size: 11px;
    padding: 5px 14px;
    text-transform: uppercase;
	margin-right: -20px;
}
.bit-link-sell-badge {
    background: #E82529;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    color: #fff;
    font-size: 11px;
    padding: 5px 14px;
    text-transform: uppercase;
	margin-right: -20px;
	flex: unset !important;
}

.bit-reksa-blue-wrap {
    border-left: 5px solid #2478B4;
    padding: 8px 10px 10px 0;
}
.bit-reksa-blue-wrap .bit-reksa-pic {
	margin-left: 12px;
}
.bit-reksa-blue-wrap .bit-reksa-pic + div h3 {
    display: flex;
    margin: 3px 0 -2px;
    align-items: center;
}
.bit-link-input-slide {
	position: relative;
    text-align: left;
}
.bit-link-input-slide p {
	font-size: 13px;
}
.bit-link-input-slide input {
    border: 1px solid #E4E4E4;
	border-radius: 5px;
	font-size: 16px;
	font-weight: bold;
	margin: 10px 0;
    padding: 10px 65px 10px 14px;
    text-align: right;
    width: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.bit-link-input-slide label {
    color: #b5b5b5;
    font-size: 13px;
    position: absolute;
    right: 13px;
    top: 39px;
}
.bit-link-slide {
	height: 40px;
}
.bit-link-slide .am-slider-handle {
	transform: translate(-21px, -24px);
    padding: 34px;
    background: transparent;
	border: none;
	width: 22px;
    height: 22px;
}
.bit-link-slide .am-slider-handle:after {
	content: "";
	position: absolute;
    margin-left: -12px;
    margin-top: -10px;
    width: 22px;
    height: 22px;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #ff0000;
    background-color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.bit-link-slide .am-slider {
    margin: 15px 12px !important;
}
.bit-link-slide .am-slider-rail {
    position: absolute;
    width: calc(100% + 20px);
    background-color: #ddd;
    margin-left: -10px;
    height: 2px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.bit-link-slide .am-slider-track {
    background-color: #ff0000;
    position: absolute;
    left: 0;
    height: 2px;
    border-radius: 2px;
    margin-left: -10px;
}
.bit-link-reksa-product {
	border-left: 5px solid #2478B4;
	border-right: 0;
	margin-top: 8px;
	position: relative;
}
.bit-link-sell-desc {
	font-size: 14px;
	text-align: left;
}
.bit-link-sell-flex {
    border-bottom: 1px solid #EDEDED;
    display: flex;
    margin: 12px 0;
    padding-bottom: 12px;
}
.bit-link-sell-flex h3 {
	font-size: 16px;
	font-weight: bold;
}
.bit-link-sell-flex p {
	font-size: 12px;
}
.bit-link-sell-flex > div:first-child {
	background: #E82529;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    display: flex;
    font-size: 10px;
    margin-right: 16px;
    padding: 0px 10px 0 5px;
    height: 25px;
}
.bit-link-sell-flex > div:first-child img {
    margin-right: 2px;
    width: 22px;
}
.bit-link-border-vertical {
	border-bottom: 1px solid #EDEDED;
	border-top: 1px solid #EDEDED;
	padding-left: 15px;
}
.pemilik-rekening-box {
    border: 1px dashed #E4E4E4;
    border-radius: 5px;
    display: flex;
    margin-top: 16px;
    padding: 10px 10px 12px;
    position: relative;
    width: 100%;
    -moz-user-select: none;
    -ms-user-select: none; 
    -khtml-user-select: none; 
    -webkit-user-select: none; 
    -webkit-touch-callout: none;
}
.bit-first-rek-box {
    border-right: 1px dashed #E4E4E4;
    margin-right: 16px;
    width: 50%;
    padding-right: 4px;
}
.bit-last-rek-box .paynote-1 {
    font-weight: bold;
}
.bit-first-rek-box, .bit-last-rek-box {
    text-align: left;
}
.bit-last-rek-box {
    width: 50%;
}
.bit-last-rek-box .paynote-3 {
    font-weight: normal;
}
.bit-margin-top-10 {
    margin-top: 10px;
}
.bit-flex-paynote {
    display: flex;
    flex-wrap: wrap;
}
.bit-flex-paynote div {
    flex-basis: auto;
    margin-bottom: 5px;
	font-weight: bold;
	text-transform: uppercase;
}
.pemilik-rekening-title {
    color: #A5A5A5;
    font-size: 12px;
    padding: 0 0 5px;
}
.bit-link-bank {
    background: #074C90;
    border-radius: 2px;
    color: #fff;
	font-family: 'beausans-semibold' !important;
    font-size: 12px;
    padding: 2px 4px;
}
.bit-link-bank-owner {
	font-size: 13px;
	font-weight: bold;
	margin: 3px 0 2px;
}
.bit-link-bank-number {
	font-size: 12px;
}
.am-modal-body {
    max-height: calc(100% - 30px);
}
.bit-link-btn-red-full.disable {
    opacity: 0.4;
}
.bit-link-modal-wrapper .am-modal-content {
	padding: 0 !important;
}
.bit-link-modal-wrapper .am-modal-body {
	max-height: 100%;
	overflow: auto;
}
.bit-link-cust-header {
    padding: 12px 20px 5px;
}
.bit-link-custom-content {
	margin-top: 0;
}
.bit-black-color {
	color: #333333;
}
.am-navbar-light {
	color: #00AB6B !important;
	font-size: 12px !important;
}
.bit-link-uploaded-img {
	border:1px solid #ededed;
	border-radius: 5px;
	height:100px;
	margin:18px 0;
	overflow: hidden;
	padding: 8px 0;
    position: relative;
	width:100%;
}
.bit-link-uploaded-img img {
	height: 100%;
}
.bit-link-cust-margin {
	margin: 14px 0;
}
.signature-canvas-box {
    margin-bottom: 100px;
}
.bit-link-modal-bank .am-navbar-light {
    margin: -12px 0 0px;
}
.bit-link-modal-bank .am-navbar-title {
    font-weight: bold;
    font-size: 15px;
    color: #333;
}
.bit-link-modal-bank .am-navbar-right {
    font-size: 14px;
    margin-left: 12px;
    margin-right: 0;
}
.bit-link-modal-bank .am-list-content {
	font-size: 13px !important;
}
.bit-link-modal-bank .am-list-item {
	padding-left: 0px;
}
.bit-link-modal-bank .am-search {
    background-color: #fff;
	border: 1px solid #ededed;
	height: 35px;
    margin-bottom: 10px;
}
.am-search-cancel {
	color: #00AB6B !important;
	font-size: 14px !important;
}

/* register form ktp and other from bibit */
.edd-box img {width: 38vw; height: auto; }
.edd-box-img {
	display: flex;
    justify-content: center;
    padding: 20px 10px;
}
.edd-box-img:after {
    display: table;
    content: '';
    clear: both;
}
@media screen and (max-width: 320px) {
    .edd-box img {
        width: 110px;
        display: block;
    }
}
.edd-box {
	background: #fff;
	font-size: 13px;
	text-align: left;
}
.edd-photo {float: right;}
.edd-photo:nth-child(1) {float: left;}
.edd-photo + .edd-photo {margin-left: 20px;}
.edd-box .am-flexbox-item {
    height: 100px;
    position: relative;
    width: 100%;
}
.edd-box .am-list-header {padding: 10px 0 10px 0 }
.edd-box .am-image-picker-list {padding: 0}
.kkcc {width: 100%;}
.edd-title {
    width: 100%;
    color: #333;
    font-weight: bold;
    font-size: 15px;
}
.eddlist ol {padding: 0 15px; margin: 0}
.eddlist li {margin-bottom: 10px; padding-left: 5px; }
.edd-photo {position: relative}
.edd-photo span {
    position: absolute;
    bottom: -11px;
    right: -11px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 3px #fff;
    height: 24px;
    width: 24x;
    border-radius: 20px;
    background: #989898;
    padding: 2px;
    z-index: 1;
}
.edd-photo span.redback {
    background: #ee4a49;
}
.edd-photo span.greenback {
    background: #00ab6b;
}

/* signature from bibit */
.signature-box {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 1000;
}
.signature-canvas-box canvas{
    width: 100%;
}
.signature-canvas-ket {
	font-size: 14px;
    padding-top: 20px;
    text-align: center;
    color: #6b6b6b;    
}
.signature-canvas-box > div:nth-child(1) {
    border: solid 2px #dadada;
   /*  height: 280px; */
}
.tw-btn {
    width: calc(50% - 10px);
}
.tw-btn span {
    height: 40px;
    line-height: 40px;
    width: 100%;
    border: solid 1px #333;
    background: #fff;
    color: #333;
    border-radius: 4px;
    display: inline-block;
	font-family: 'beausans-semibold' !important;
    text-align: center;
}
.tw-btn.primary span {
    border: 0;
    background: #FF0000;
    color: #fff;
}
.tw-btn-box {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

/* steps from bibit */
.index-registration-step .am-steps-item {
	flex-direction: column; display: flex; justify-content: content; align-items: center; width: calc(100% / 3);
}
.index-registration-step .am-steps-label-vertical .am-steps-item-tail { margin-left: 50%;}
.index-registration-step .am-steps-label-vertical .am-steps-item-icon {margin: 0;}
.index-registration-step {padding: 20px; padding-bottom: 0}
.index-registration-step .am-steps-label-vertical .am-steps-item-icon {margin: 0;}
.am-steps-item-finish .am-steps-item-tail:after  {background-color: #E82529 !important}
.am-steps-item-finish .am-steps-item-icon > .am-steps-icon {color: #E82529 !important}
.am-steps-item-process .am-steps-item-icon { border-color: #E82529 !important;}
.am-steps-item-process .am-steps-item-icon {background-color: #E82529 !important}
.am-steps-item-finish .am-steps-item-icon > .am-steps-icon {
    color: #E82529;
}
.verifikasi-box {margin: 20px 0 14px; margin-left: 20px; }	
.verifikasi-step {
	border-bottom: solid 1px #ededed; padding: 8px 12px;
	display: flex;
	font-size: 13px;
	justify-content: space-between;
	padding-left: 0;
}
.verifikasi-step:nth-child(1) {
	border-top: solid 1px #ededed;
}
.verifikasi-step div:nth-child(1) {width: 16px; margin-right: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.verifikasi-step div:nth-child(2) {width: calc(100% - 20px);
	display: flex;
	justify-content: space-between;
}
.verifikasi-step span {
	width: 16px;
	height: 16px;
	border-radius: 16px;
	border: solid 1px #E82529;
	display: flex;
	justify-content: center;
	align-items: center;
}
.verifikasi-step span svg {
    stroke: #E82529;
}
/* .verifikasi-step.done span {
	background: #FF0000;
	border: solid 1px #FF0000;
} */
.am-steps-small .am-steps-item-title {
	font-size: 14px !important;
}
.buyInputSuggest::-webkit-scrollbar {
	height: 1px;
}
.buyInputSuggest {
	overflow-x: auto !important;
	display: flex !important;
	margin-bottom: 20px;
	padding-top: 2px;
	/* width: calc(100% + 15px); */
}
.buyInputSuggest span {
	color: #BCBCBC;
	text-align: center;
	padding: 0 10px;
	margin: 0px 10px 0 0px;
	border-radius: 10px;
	height: 20px;
	line-height: 20px;
	border: solid 1px #BCBCBC;
	font-size: 12px;
	display: flex;
    align-items: center;
}
.buy-money-input {
    padding:10px 0 0 0;
}
.buy-money-input input {
    border: none;
	font-family: 'muli-regular' !important;
    font-size: 17px;
    font-weight: bold;
    padding-bottom: 12px;
    width: 100%;
    text-align: center;
}
.buy-money-input +  .loaderbar {margin-bottom: 20px}
.loaderbar + .loaderbarhack {display: none}
.loaderbarhack {
	height: 2px;
	width: 100%;
	background: #00ab6b;
	margin-bottom: 20px;
}
.loaderbar {
	height: 2px;
	width: 100%;
	position: relative;
	overflow: hidden;
	background-color: #707070;
	border-radius: 2px 2px 0 0;
	margin-top: 0;
	transition: .3s ease all
	border-top-left
}
.loaderbar:before {
	height: 2px;
	display: block;
	position: absolute;
	content: "";
	left: -200px;
	width: 200px;
	background-color: #707070;
	animation: loading 2s linear infinite
}
.confirmRadio {
	position: relative;
	display: flex;
	align-items: stretch;
	line-height: 18px;
	margin-left: 15px;
	padding-bottom: 16px;
	margin-top: 10px;
	text-align: justify;
	font-size: 13px;
}
.confirmRadio p {text-align: justify; padding-left: 15px; font-size: 13px;} 
.confirmRadio .am-checkbox.am-checkbox-checked .am-checkbox-inner {
    border-color: #00AB6B;
	background: #00AB6B;
	left: -14px;
}
.confirmRadio .am-checkbox-inner {
    width: 18px;
    height: 18px;
}
.confirmRadio .am-checkbox-inner:after {
    top: 1.5px;
    right: 6px;
    width: 4px;
    height: 8px;
}
.confirmRadio a {
    color: #FF0000;
}
.error {
	background: #fff;
    color: #f24444;
    border-radius: 5px;
    padding: 10px 0;
	font-size: 13px;
	line-height: 18px;
	padding: 10px 0;
	position: relative;
	text-align: left;
    z-index: 9;
}
.bit-link-card .error {
    margin-left: 14px;
    margin-right: 12px;
    padding: 0;
}
.bit-link-card .error:last-child {
    padding-bottom: 8px;
}
.closehome {
	margin-bottom: -10px;
	padding: 10px 10px 0 0;
	text-align: right;
}
.reksadana-chart {
    transform: translateX(-18px);
    width: 114%;
}
.bit-link-loader-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}
.am-activity-indicator-content {
	max-width: 190px !important;
	padding: 16px 20px !important;
	-webkit-transform:translateZ(1px) !important;
	transform: translateZ(1px) !important;
	z-index: 9999 !important;
	position: relative !important;
}
.am-activity-indicator.am-activity-indicator-toast {
	height: 100% !important;
	-webkit-transform:translateZ(1px) !important;
	transform: translateZ(1px) !important;
	z-index: 9999 !important;
}

.edd-notif {
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: solid 1px #E4E4E4;
	padding-bottom: 10px;
    margin-bottom: 20px;
	margin-left: -20px;
	width: calc(100% +  40px);
	margin-top: -10px;
}
.edd-notif img {
	height: 50px;
}
.edd-notif-text {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	position: relative;
	padding-right: 50px;
}
.edd-notif-text span {
	display: block;
	margin: 0;
}
.edd-notif-text span:nth-child(2) {
	font-size: 13px;
	padding-top: 5px;
	color: #E82529;
}
.edd-notif-text svg {
	position: absolute;
	right: 0;
	top: 25%;
}
.edd-notif-img {
	padding-right: 20px;
}
.login-register-icon img {
	height: 70px;
	width: auto;
}
.login-register-desc {text-align: left; margin: 20px 0 15px 0;}

.am-modal-body .am-list-item.am-input-item {
	padding-left: 0; margin-bottom: 20px;
}
.am-modal-body .am-list-item .am-list-line {
	padding-right: 0; 
	border-bottom: solid 1px #FF0000 ;
}
.am-button-primary {
	background: #FF0000 !important;
	border-radius: 50px;
 }
.bit-link-otp-modal {
	padding:  0 20px 20px;
}
.login-register-right-text {
	font-size: 14px;
}
.bit-modal-change-email {
	padding-bottom: 10px;
    text-align: left;
} 
.bit-modal-change-email .am-list-header {
	color: #000;
	padding: 15px 15px 9px 0px;
}
.bit-link-btn-white-full {
	background: #FFF;
	border: 1px solid #333333;
	border-radius: 50px;
	color: #333;
	font-family: 'beausans-semibold' !important;
	font-size: 14px;
	padding: 8px 14px;
    height: 40px;
	width: 100%;
}
.bit-modal-email-sent {
	font-size: 14px;
	padding: 10px 0;
}
.bit-modal-email-sent .login-register-heading {
    margin: 12px 0;
}
.bit-link-reksa-radio .am-checkbox.am-checkbox-checked .am-checkbox-inner {
    border-color: #E82529;
	background: #E82529;
}
.bit-link-reksa-radio {
	border-bottom: 1px solid #e4e4e4;
	display: flex;
	padding: 8px 0 8px !important;
}
.bit-link-reksa-radio .am-list-thumb {
	order: 2;
}
.bit-link-reksa-radio .am-checkbox-input {
	min-height: 70px;
}
.bit-link-reksa-radio .am-list-thumb .am-checkbox-inner {
	left: unset !important;
}
.bit-link-reksa-radio .am-list-line {
	border-bottom: 0 !important;
}
.bit-link-reksa-radio .am-list-line::after {
	background: transparent !important;
}
.bit-flex-start {
	align-items: flex-start;
}
.bit-link-reksa-radio .bit-reksa-item span {
	color: #000;
}
.bit-select-product-wrap {
	margin: 0;
    max-height: calc(100% - 100px);
    overflow-y: auto;
}
.bit-link-error-404 {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding: 40px;
}
.bit-link-error-404 img {
	width: 200px;
}
.bit-link-reksa-radio .bit-reksa-item h3 {
    max-width: calc(100% - 41px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.bit-link-reksa-radio .bit-reksa-pic {
	min-width: 34px;
}
.bit-reksa-modal .am-modal-content {
    padding: 0 !important;
}
.bit-reksa-modal .am-modal-body {
	max-height: 100%;
    padding: 20px;
}
.bit-reksa-modal .bit-link-modal-header {
    background: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-left: -20px;
    margin-top: -20px;
    padding: 10px 20px;
    position: fixed;
    width: 100%;
    z-index: 9;
}
.bit-reksa-modal .bit-reksa-pic {
	display: flex;
    align-items: center;
    justify-content: center;
}
.bit-reksa-modal .bit-reksa-pic img {
	height: 100%;
	width: 100% !important;
}
.bit-link-fixed-btn-cont {
    background: #fff;
    margin-bottom: -20px;
    padding: 10px 0;
    position: relative;
    width: 100%;
    z-index: 10;
}
.bit-modal-pin-fix {
    bottom: 0;
    top: -4px;
}
.bit-link-prospectus {
	display: flex;
	font-size: 13px;
	font-weight: bold;
	justify-content: space-between;
	margin-bottom: 20px;
}
.bit-link-prospectus div {
	align-items: center;
	border: solid 1px #ededed;
	display: flex;
	justify-content: space-between;
	padding: 6px 10px;
	width: 48%;
}
.bit-link-perform-info {
	color: #9ca4ac;
	font-size: 10px;
	margin: 14px 0 5px;
	text-align: left;
}

.bit-link-profile .am-list-item .am-input-control input:disabled {
    color: #000 !important;
}
.bit-link-profile .am-list-body::after {
	background-color: #fff !important;
}
.bit-link-profile .addressBox {
	font-size: 16px;
	margin-top: 7px ;
	padding-bottom: 7px; 
}
.bit-link-order-card {
    background: #fff;
    border-radius: 4px;
    border: solid 1px #ededed;
    margin-top: 4px;
    overflow: hidden;
    width: 100%;
}
.bit-link-order-card-top {
	border-bottom: solid 1px #ededed;
    font-size: 13px;
	padding: 10px 18px;
	text-align: left;
}
.bit-link-order-card-top span:last-child {
	font-weight: bold;
}
.bit-link-order-card-top.date {
	align-items: center;
    display: flex;
    justify-content: space-between;
}
.bit-link-order-card-top.date div:last-child span {
    align-items: center;
    color: #9CA4AC;
    display: flex;
	font-size: 10px;
	font-weight: normal;
}
.bit-link-order-card-bottom {
	border-left: 4px solid #E82529;
    padding: 14px 18px;
}
.bit-link-order-nominal {
	align-items: center;
	display: flex;
	font-size: 13px;
	margin: 18px 0 0;
	justify-content: space-between;
}
.bit-link-order-nominal div:first-child {
	font-size: 12px;
    color: #b5b5b5;
}
.bit-link-pending {
	border: 0;
	color: #000;
}
.badge-porto-pending-wrap {
    margin-bottom: 14px;
    text-align: left;
}
.badge-porto-pending .am-badge-text {
	background: #fff;
	color: #9CA4AC;
	padding-left: 0;
	text-align: left;
}
.bit-link-modal-pencairan {
	text-align: center;
}
.bit-link-modal-pencairan p {
	color: #333;
	margin: 20px 0;
}
.puller-box {
	width: 100%;
}
.ptr--ptr {
	box-shadow: none !important;
}
.bit-link-puller-wrap {
    position: absolute;
    overflow-y: auto;
	height: calc(100% + -55px);
	width: 100%;
}
.bit-link-puller-half-wrap {
    position: absolute;
    overflow-y: auto;
	height: calc(100% + -220px);
	width: calc(100% + -40px);
}
/* Terms & Conditions */
.terms-conditions,
.privacy-policy {
	margin: 0px 15px;
	padding-bottom: 20px;
	overflow: scroll;
	text-align: justify;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
}
.term-conditions::-webkit-scrollbar,
.privacy-policy::-webkit-scrollbar {
	width: 0px;
	background: transparent;
	display: none;
}
.terms-conditions h3,
.privacy-policy h3 {
	font-weight: bold;
	line-height: 22px;
	margin-bottom: 14px;
	text-align: center
}
.terms-conditions h4 {
	font-weight: bold;
	margin-bottom: 12px;
}
.terms-conditions p,
.privacy-policy p {
	line-height: 1.58em;
}
.terms-conditions b,
.privacy-policy b {
	font-weight: bold;
}
.terms-conditions ul {
    margin: 12px 0 12px 12px;
}
.terms-conditions ul li {
    margin-left: 5px;
    list-style: disc;
}
.terms-conditions ul li ul li {
    margin-left: 5px;
    list-style: circle;
}
.bit-link-modal-head-product {
    background: #fff !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    margin-left: -20px !important;
    margin-top: -20px !important;
    padding: 10px 20px 5px !important;
    position: relative !important;
    width: calc(100% + 40px) !important;
    z-index: 9 !important;
}
/* .empty-chart {
	padding-top: 20px;
    padding-bottom: 20px;
} */
.empty-chart {
	display: flex;
    align-items: center;
    justify-content: center;
    height: 56.5vw;
    margin: 0 auto 20px auto;
    border-radius: 4px;
    width: 100%;
}
.reksa-chart-nav.linkaja ul li {
    color: #00ab6b;
    display: inline-block;
    margin: 5px 10px;
    font-size: 12px;
}
.reksa-chart-nav li.active a {
    color: #333;
    font-weight: bold;
    border-bottom: solid 2px #00ab6b;
}
.boxloader {
	background: #fff;
	width: 100%;
    height: 68.2vw;
	display: flex;
	align-items: center;
	justify-content: center;
}
.spinner {
	margin: 50px;
	height: 30px;
	width: 30px;
	animation: rotate 0.8s infinite linear;
	border: 3px solid #ccc;
	border-right-color: transparent;
	border-radius: 50%;
	opacity: 0.8;
}
@keyframes rotate {
	0%    { transform: rotate(0deg); }
	100%  { transform: rotate(360deg); }
}
.bit-stay-header {
	left: 0;
	position: absolute;
    top: 0;
}
.bit-link-main-content.padding-wrap {
	margin-top: 0;
    padding-top: 60px;
    height: 100%;
}
.bit-error-desc-reject {
	padding: 20px;line-height: 18px;
}
.bit-link-card .am-button {	
	font-size: 13px;
}
.bit-link-uploaded-img + .bit-link-btn-red-full {
	margin-bottom: 30px;
}

.bit-bank-change {
    border: 1px solid #ededed;
	border-radius: 5px;
	margin-top: 35px;
    overflow: hidden;
	position: relative;
	width: 100%;
}
.bit-bank-change .bit-change-row:last-child {
    border-bottom: 0;
}
.bit-bank-change.index .bit-change-row .am-checkbox-checked .am-checkbox-inner:before {
    content: 'default';
    display: block;
    position: absolute;
    left: 21px;
    right: -15px;
    top: 2px;
    z-index: -1;
    font-size: 12px;
}
.bit-bank-change.index .bit-change-row .am-checkbox-inner:after {
    top: 3.5px;
    right: 7px;
}
.bit-link-switch-bank-modal .modal-close-flat {
    float: right;
}
.bit-link-switch-bank-modal h3 {
    font-weight: bold;
    float: left;
}
.bit-link-switch-bank-modal .bit-change-row,
.bit-change-bank-setting .bit-change-row {
	align-items: center;
	display: flex;
	justify-content: space-between;
	height: 50px;
}
.bit-link-switch-bank-modal .bit-change-row span,
.bit-change-bank-setting .bit-change-row span{
	order: 2;
}
.bit-change-info {
    align-items: center;
    display: flex;
    width: calc(100% - 60px);
}
.bit-change-info .bankName {
    display: block;
    margin-top: 0;
}
.bit-change-info div:first-child {
	min-width: 60px;
	max-width: 120px;
}
.bit-change-info div:last-child {
    margin-left: 10px;
}
.bit-change-info .bit-flex-paynote span {
    margin-bottom: 0;
}
.bit-link-switch-bank-modal .paynote-1.bca.prox-condensed-bold,
.bit-change-bank-setting .paynote-1.bca.prox-condensed-bold {
    border-radius: 2px;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    margin-left: 10px;
    padding: 0 4px;
}
.bit-change-bank-setting .paynote-1.bca.prox-condensed-bold {
	align-items: center;
    display: flex;
}
.bit-link-switch-bank-modal .paynote-0,
.bit-change-bank-setting .paynote-0 {
	text-align: left;
}
.bit-link-switch-bank-modal .paynote-0 .bankName,
.bit-change-bank-setting .paynote-0 .bankName {
	font-size: 11px;
	font-weight: bold;
}
.bit-link-switch-bank-modal .am-checkbox,
.bit-change-bank-setting .am-checkbox {
	margin-right: 10px;
}
.bit-info-icon-right {
    position: absolute;
    right: 8px;
    top: 15px;
    margin-left: 5px;
    z-index: 99;
}
.bit-change-bank-setting .bit-bank-change {
	margin-top: 0;
}
.bit-change-img {
    margin: 20px 0;
    text-align: center;
}
.bit-change-rek-info {
	margin: 15px 0;
	text-align: left;
}
.bit-change-rek-info p {
    border-bottom: 1px solid #ededed;
    font-size: 11px;
    margin-bottom: 10px;
    padding-bottom: 6px;
    margin-left: 18px;
    position: relative;
}
.bit-change-rek-info + .cust-twins-no-color .am-button {
    color: #666;
}
.bit-change-rek-info p:last-child {
    border-bottom: 0;
}
.bit-change-rek-info p:before {
    background: #00AB6B;
    border-radius: 50%;
    content: '';
    display: block;
    height: 8px;
    width: 8px;
    position: absolute;
    left: -15px;
    top: 3px;
    z-index: 9;
    font-size: 12px;
}
.cust-twins, .cust-twins-no-color {
    display: flex;
}
.cust-twins .am-button, .cust-twins-no-color .am-button, .cust-twins-no-color a,
.cust-twins-no-color span {
    width: calc(50% - 1px);
    border: solid 1px #e4e4e4 !important;
    border-radius: 0 !important;
    font-size: 14px;
}
.cust-twins-no-color a,
.cust-twins-no-color span {
    align-items: center;
	border-radius: 5px !important;
	color: #000;
    display: flex;
	height: 40px;
    justify-content: center;
}
.cust-twins-no-color a:first-child,
.cust-twins-no-color span:first-child {
    margin-right: 10px;
}
.cust-twins-no-color a:last-child {
	background: #FF0000;
    border-radius: 5px !important;
	border: 1px solid #FF0000 !important;
	color: #fff;
	margin-right: 0;
}
.login-register-icon {
	text-align: left;
}
.login-register-icon img {
    height: 80px;
    margin-bottom: 20px;
}
.login-register-heading {
    color: #333;
    font-weight: bold;
    font-size: 17px;
	margin-bottom: 10px;
	text-align: left;
}
.login-register-heading + div {
	text-align: left;
}
.bit-link-bottom-stay-content {
    bottom: 20px;
    position: absolute;
    width: calc(100% - 40px);
}
.bit-change-bank-form .am-list-item.am-input-item {
    padding-left: 0;
}
.bit-change-bank-form .am-list-header {
    margin-top: 12px;
	padding: 0;
	text-align: left;
}
.bit-change-bank-form .am-list-line {
    border-bottom: solid 1px #ededed !important;
}
.bit-change-bank-form .am-list-item .am-list-line .am-list-content {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
}
.bit-change-bank-form .am-list-item .am-input-control input {
    font-size: 14px !important;
}
.bit-change-bank-form .am-list-item {
    padding-left: 0;
}
.bit-change-bank-form .am-list-item .am-list-line {
    padding-right: 0;
}
.bit-change-bank-form .kettxt {
    border-top: 0;
}
.bit-change-bank-form .bb-title {
    margin-top: 18px;
}
.bit-change-bank-form .am-list-extra {
	display: none;
}
.bit-change-bank-form .am-list-line::after {
    background: #fff !important;
}
.kettxt {
    border-top: solid 1px #ededed;
    display: inline-block;
    padding-top: 5px;
    font-size: 12px;
	color: #666;
	margin-bottom: 20px;
	margin-top: 5px;
	text-align: left;
	width: 100%;
}
.bit-link-modal-setting .am-modal-content {
    min-height: 130px;
}
.bit-link-modal-setting .bit-link-modal-header h3 {
    align-items: center;
    display: flex;
}
.bit-link-modal-setting .bit-link-modal-header h3 img {
	margin-right: 6px;
}
.bit-modal-setting-content {
	margin: 10px 0;
	padding: 10px 0 0;
}
.bit-modal-setting-content p {
    padding: 10px 0;
    text-align: center;
}
.bit-modal-setting-row {
    align-items: center;
    border-bottom: 1px solid #EDEDED;
	display: flex;
	font-size: 14px;
	justify-content: space-between;
	padding: 8px 0;
}
.bit-modal-setting-row input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}
.bit-modal-setting-row label {
	cursor: pointer;
    text-indent: -9999px;
    width: 30px;
    height: 16px;
    background: #ccc;
	display: inline-block;
	border: 1px solid #666;
    border-radius: 100px;
    position: relative;
}
.bit-modal-setting-row label:after {
	border: 1px solid #666;
	content: '';
    position: absolute;
    top: -0.5px;
    left: -1px;
    width: 13px;
    height: 13px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}
.bit-modal-setting-row input:checked + label {
    background: rgba(232, 47, 31, 0.45);
	border: 1px solid #E82529;
}
.bit-modal-setting-row input:checked + label:after {
	border: 1px solid #E82529;
	left: calc(100% - 4px);
	transform: translateX(-70%);
}
.bit-modal-setting-row label:active:after {
	width: 20px;
}
.bit-link-dual-button {
	align-items: center;
	display: flex;
	margin: 20px 0 10px;
	justify-content: space-between;
}
.bit-link-dual-button button {
	border-radius: 20px;
	font-weight: bold;
    padding: 11px;
    width: calc((100% / 2) - 10px);
}
.bit-link-dual-button button:first-child {
	background: #fff;
	border: 1px solid #E82529;
	color: #E82529;
}
.bit-link-dual-button button:last-child {
	background: #E82529;
	border: 1px solid #E82529;
	color: #fff;
}
.bit-link-full-row {
	text-align: left;
	width: 100%;
}
.bit-link-bank-separator {
    background: #ededed;
    height: 1px;
    margin: 20px 0 10px;
    width: 100%;
}
.font-size-11 {
	font-size: 11px;
}
.font-normal {
	font-weight: normal !important;
}
.bit-bank-info img {
    margin: 50px 0;
    width: 150px;
}
.bit-bank-info h3 {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 14px;
}
.bit-bank-info p {
	color: #aaa;
	margin-bottom: 30px;
}
.bit-link-shimmer {
	float: left;
	height: 10px;    
	margin: 18px 20px;
    width: 180px;
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
	background-size: 800px 104px;
	border-radius: 5px;	
}
@keyframes placeHolderShimmer{
	0%{
			background-position: -468px 0
	}
	100%{
			background-position: 468px 0
	}
}

.bit-deactive-title {
 	margin-top: 28px;
 	margin-bottom: 8px;
 	font-size: 16px;
 	line-height: 22px;
	font-weight: 700;
}

.bit-deactive-message {
	font-size: 14px;
	line-height: 150%;
}

.bit-transaction-message {
	margin-bottom: 16px;
	border: 1px solid #E4E4E4;
	padding: 12px 8px;
	color: #373737;
	border-radius: 4px;
	text-align: center;
	font-size: 12px;
	line-height: 18px;
	background-color: #ee5b5e;
	color: #fff;
}

.badge-on-progress {
	background-color: #fff4da;
	color: #ffc330;
	font-size: 10px;
	white-space: nowrap;
	padding: 2px 4px;
	border-radius: 4px;
	margin-right: 12px;
	font-weight: 700;
}

.bit-info-img, .bit-info-img > img {
	height: 24px;
	margin-right: 4px;
}